<template>
  <div class="detail-info"
       v-if="statusData.length !== 0"
       :style="[{'min-height': minHeight+'px'}]">
    <div class="header">
      <span>状态信息</span>
    </div>
    <div class="content">
      <div class="right-box">
        <span class="right-title">最新状态：</span>
        <el-card class="card-box"
                 shadow="never">
          <div class="card-label div-row">
            <div class="label-title">状&ensp;态&ensp;ID：</div>
            <span class="shenglue-1 label-content"
                  v-if="statusDetail.id">{{statusDetail.id}}</span>
            <i class="el-icon-document-copy"
               style="margin-left:10px"
               v-if="statusDetail.id"
               @click="copy($event, statusDetail.id)"></i>
            <span v-else>无</span>
          </div>
          <div class="card-label div-row">
            <div class="label-title">最新记录：</div>
            <span class="shenglue-1 label-content"
                  v-if="statusDetail.parentHash">{{statusDetail.parentHash}}</span>
            <i class="el-icon-document-copy"
               style="margin-left:10px"
               v-if="statusDetail.parentHash"
               @click="copy($event, statusDetail.parentHash)"></i>
            <span v-else>无</span>
          </div>
          <div class="card-label div-row">
            <div class="label-title">状态版本：</div>
            <span>{{statusDetail.statusVersion}}</span>
          </div>
          <div class="card-label">
            <div class="label-title">状态数据：</div>
          </div>
          <pre v-html="syntaxHighlight(statusDetail.json)"></pre>
        </el-card>
      </div>
      <el-timeline class="timeline-box">
        <el-timeline-item v-for="(item, index) in statusData"
                          :key="index"
                          :type="type"
                          :color="color"
                          :size="size"
                          :icon="icon"
                          :timestamp="tf(item.timestamp,'YYYY-MM-DD HH:mm:ss')"
                          placement="top">
          <el-card class="card-box">
            <div class="card-label div-row">
              <div class="label-title">状&ensp;态&ensp;ID：</div>
              <span class="shenglue-1 label-content"
                    v-if="item.statusKey">{{item.statusKey}}</span>
              <span class="shenglue-1 label-content"
                    v-else>{{statusDetail.id}}</span>
              <i class="el-icon-document-copy"
                 style="margin-left:10px"
                 @click="copy($event, item.statusKey||statusDetail.id)"></i>
            </div>
            <div class="card-label div-row">
              <div class="label-title">最新记录：</div>
              <span class="shenglue-1 label-content"
                    v-if="item.parentHash">{{item.parentHash}}</span>
              <i class="el-icon-document-copy"
                 style="margin-left:10px"
                 v-if="item.parentHash"
                 @click="copy($event, item.parentHash)"></i>
              <span v-else>无</span>
            </div>
            <div class="card-label div-row">
              <div class="label-title">状态版本：</div>
              <span>{{item.statusVersion}}</span>
            </div>
            <div class="card-label">
              <div class="label-title">状态数据：</div>
            </div>
            <pre v-html="syntaxHighlight(item.json)"></pre>
          </el-card>
        </el-timeline-item>
        <div class="div-row div-more"
             v-if="statusData.length < total"
             @click="getMore()">查看更多<i class="el-icon-d-arrow-right div-more"></i></div>
      </el-timeline>

    </div>
  </div>
</template>
<script>
import publicFunction from '@/utils/publicFunction.js'
import timeFormat from '@/utils/timeFormat.js'
import Clipboard from 'clipboard'
export default {
  name: 'statusDetail',
  data() {
    return {
      minHeight: 100, // 动态屏幕高度
      // 状态数据
      statusData: [],
      statusDetail: '',
      total: 0, // 总条数
      currentPage: 0, // 默认0开始拿数据
      statusSize: 2, // 设置每次查询条数
      // 时间轴样式配置
      type: 'primary',
      color: '',
      size: 'large',
      icon: 'el-icon-check'
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        // 清空数据
        this.statusData = []
        this.statusDetail = ''
        // 获取状态数据
        this.getData(0)
        this.getDetail()
      },
      deep: true,
      immediate: false // 第一次不会立刻监听handler事件
    }
  },
  mounted() {
    // this.getData()
    this.minHeight = window.innerHeight - 124
  },
  methods: {
    // 点击更多
    getMore() {
      this.currentPage += this.statusSize
      if (this.currentPage < this.total) {
        this.getData(this.currentPage)
      }
    },
    async getData(num) {
      let options = {
        page: num,
        limit: this.statusSize,
        statusKey: this.datas.searchKey
      }
      const { data } = await this.$api.chainQL.ledgerStatus(options)
      if (data) {
        if (data.listLedger.total !== 0) {
          // 总条数
          this.total = data.listLedger.total
          let array = this.statusData
          array.concat(data.listLedger.ledger)
          this.statusData = array.concat(data.listLedger.ledger)
          this.$emit('status-say', 3)
        } else {
          this.$emit('status-say', 2)
        }
      }
    },
    async getDetail() {
      let options = {
        page: 0,
        limit: 10,
        id: this.datas.searchKey || null,
        parentHash: null,
        statusVersion: null
      }
      const { data } = await this.$api.chainQL.listWorkStatus(options)
      if (data.listWorkStatus !== null && data.listWorkStatus !== {}) {
        this.statusDetail = data.listWorkStatus.workStatus[0]
      }
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 对象格式处理
    syntaxHighlight(json) {
      return publicFunction.syntaxHighlight(JSON.parse(json))
    },
    getInfo(value) {
      // console.log(JSON.parse(JSON.stringify(value)).name)
      // if (JSON.parse(value) === 'object') {
      this.info = this.syntaxHighlight(JSON.parse(value))
      // } else {
      //   this.info = this.syntaxHighlight(value)
      // }
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-info {
  min-width: 1160px;
  background: #fff;
  padding-bottom: 2px;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 16px;
    border-bottom: 1px solid #cecece;
    background: #fff;
  }
  .content {
    // background: rgb(143, 105, 105);
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .timeline-box {
      min-width: 550px;
      width: 50%;
      // position: relative;
      // left: 2%;
    }
    .right-box {
      min-width: 500px;
      width: 47%;
      display: flex;
      flex-direction: column;
    }
    .right-title {
      color: #3f536e;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .card-box {
      font-size: 15px;
    }
    .card-label {
      margin: 15px 0;
    }
    .label-title {
      color: #3f536e;
      font-weight: 600;
      width: 90px;
      padding-left: 10px;
      text-align: left;
      // background: seagreen;
    }
    .label-content {
      width: 350px;
      margin-top: 14px;
    }
    .div-more {
      justify-content: center;
      color: $--color-primary;
    }
    .div-more:hover {
      font-weight: 600;
    }
    .div-column {
      display: flex;
      flex-direction: column;
    }
    .div-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    // large类型节点样式
    /deep/.el-timeline-item__node--large {
      left: -2px;
      width: 18px;
      height: 18px;
    }
    // 时间戳大小
    /deep/.el-timeline-item__timestamp {
      color: #3f536e;
      line-height: 18px;
      font-size: 18px;
      font-weight: 600;
    }
    // 时间线样式
    /deep/.el-timeline-item__tail {
      position: absolute;
      left: 6px;
      height: 100%;
      border-left: 3px solid #e4e7ed;
    }
    // js样式
    /deep/ pre {
      min-height: 200px;
      background: rgb(241, 241, 241);
      border-radius: 8px;
      padding: 20px;
      margin: 10px;
      font-size: 16px;
      overflow-x: scroll;
      .string {
        color: green;
      }
      .number {
        color: darkorange;
      }
      .boolean {
        color: blue;
      }
      .null {
        color: magenta;
      }
      .key {
        color: red;
      }
    }
  }
}
</style>
